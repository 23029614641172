import React from 'react'
import Layout from '../components/Layout'
import CallBackBanner from '../components/CallBackBanner'
import PromotionBanner from '../components/PromotionBanner/PromotionBanner'
import GeneralEnquiryForm from '../components/shared/Form/GeneralEnquiryForm'
import img from '../images/industry-sector/team_industry.svg'
import IndustryTemplateLayout from '../components/IndustrySector/IndustryTemplateLayout'
import MainGrid from '../components/styling/MainGrid'
import Breadcrumb from '../components/shared/Breadcrumb/Breadcrumb'
import IndustryLeftCol from '../components/IndustrySector/IndustryLeftCol'
import IndustryRightCol from '../components/IndustrySector/IndustryRightCol'
import IndustryDescription from '../components/IndustrySector/IndustryDescription'
import logo from '../images/homepage/popin-anim/engineering.svg'
import CaseStudyContent from '../components/CaseStudy/CaseStudyContent'

const breadcrumbData = [
  {
    title: 'Home',
    url: '/',
  },
  {
    title: 'About us',
    url: '/about-us',
  },
  {
    title: 'Case Studies',
    url: '/case-studies',
  },
  {
    title: 'PPS Commercials',
    url: '/pps-commercials',
  },
]

const PPSCommercials = () => {
  return (
    <Layout
      activeLink="/case-studies"
      title="R&D PPS Commercials"
      description="R&D PPS Commercials"
    >
      <MainGrid noPaddingMobile noPaddingTablet>
        <Breadcrumb data={breadcrumbData} />
      </MainGrid>
      <IndustryTemplateLayout>
        <IndustryLeftCol
          category="Case Studies"
          title="PPS Commercials"
          backLink="/case-studies"
        />
        <IndustryRightCol>
          <IndustryDescription title="PPS Commercials" />
          <CaseStudyContent
            heading="Body Builders & Automotive specialists"
            paragraph='We hadn’t made any R&D tax credit claims before, and it was our accountant who recommended we speak to RDA. I spoke with the team at RDA and they made us feel totally at ease. As we went through the process it was extremely seamless, all the hard work was taken care of by RDA.'
            // imgDesktop={img}
            // imgMobile={img}
            alt="Aeroplane"
            logo={logo}
            content={[
              <div>
              <p>We didn’t realise we would qualify for R&D until we went through everything that counted as qualifying expenditure with us.</p>
              <p>This helped us go away and put examples together where R&D had been used. Now we record the R&D as it happens in preparation for the end-of-year reporting. We would not be looking elsewhere for people to represent us on our R&D claims.</p>
              </div>,
            ]}
          />
        </IndustryRightCol>
      </IndustryTemplateLayout>
      <PromotionBanner />
      <GeneralEnquiryForm />
      <CallBackBanner />
    </Layout>
  )
}

export default PPSCommercials
